





































































































import DatePicker from "vue2-datepicker";
import info from "@/components/_universal/list-info.vue";
import tablelist from "@/components/_universal/list-table.vue";

//Standard Components
import EVENTS from "@/constants/events";

//Controller
import ClientsController from "@/controllers/clients-controller";

export default {
  data() {
    return {
      listDataObj: {},
      EVENT_ACTION: {},
      project: {},
      isFilterOpen: false,
      dateFrom: null,
      dateTo: null
    };
  },
  methods: {
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    async loadData() {
      //Initialize Controller and pass data to be loaded/displayed
      let clientsController = new ClientsController();

      //Set Header Properties to be passed in as prop values
      this.listDataObj = await clientsController.list(null, "");
    }
  },
  components: {
    info,
    tablelist,
    DatePicker
  },
  async mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window["analytics"].page(this.EVENT_ACTION.CLIENTS.View);
    // }
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.loadData();
  }
};
